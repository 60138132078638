export default {
    basepath: "",

    portal_url: "https://eatery-portal.com/",

    api: {
        baseurl: "https://api.eatery-timemng.com/",
        version: "api/v0.1"
    },

    checksheet: {
        print_alert_count: 100,             // チェックシート実績のアラート表示件数
    },

    todaytask: {
        reload_interval_msec: 60000 * 5,    // 自動リロード間隔
        timeover_interval_msec: 60000,      // 時間切れ表示更新間隔
        timeover_taskmove_minutes: 15,      // 時間切れ移動間隔（分）
        work_ready_minutes: 10,             // 実施前表示（分）
    },

    ranking: {
        start_ym: '2023-07'                 // ランキング開始年月 2023-09
    },
    
    dashboard_v2: {
        start_ymd: '2023-12-01'             // ダッシュボード表示可能日
    },
}